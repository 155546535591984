@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@import url("https://netdna.bootstrapcdn.com/font-awesome/2.0/css/font-awesome.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css");
@import url("https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css");

/* base styles */
* {
  margin: 0;
  font-family: "Quicksand";
  color: #333;
  padding: 0;
  box-sizing: border-box;
}

*:focus,
*:active {
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  position: relative;
  display: grid;
  margin: 0;
  padding: 0;
  height: 90vh;
  width: 100vw;
  font-family: "Poppins", sans-serif;
}

main {
  min-height: calc(100vh - 3.7rem - 8rem);
  margin-top: 6.6rem;
}

canvas {
  display: block;
  margin: auto;
  object-fit: contain;
  max-width: 80vw;
  max-height: 80vh;
}

p {
  margin-top: 16px;
  margin-bottom: 16px;
}

.content {
  max-width: 90vw;
  margin: 0px auto;
}

/*
============================================================
== ADMIN PANEL
============================================================
*/
.admin-content {
  display: grid;
}

/*.admin-panel {
  display: flex;
}*/

.admin-panel-card {
  padding: 1rem;
  border-radius: 8px;
  background-color: #e3ffa8;
  color: #000000;
  margin: 0.5rem;
  -webkit-box-shadow: 0.25em 0.25em 2em rgba(0, 0, 0, 0.25);
  box-shadow: 0.25em 0.25em 2em rgba(0, 0, 0, 0.25);
}

.admin-panel-card h3 {
  font-weight: 600;
  background: #000;
  color: #fff;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0.1em 0.1em 0.1em rgba(0, 0, 0, 0.25);
}

.amin-panel-card-input {
  background: #ccc;
  border: 2px solid black;
  border-radius: 0.8rem;
  color: black;
  font-weight: bold;
  outline: none;
  height: 2rem;
  width: 300px;
  overflow: hidden;
  font-weight: 100;
  margin-right: 10px;
  padding-left: 10px;
}

.admin-panel-card-button {
  background: hsl(141, 71%, 48%);
  border: 2px solid #ccc;
  color: #fff;
  padding: 8px;
  margin: 20px 0px 10px 10px;
  border-radius: 8px;
  cursor: pointer;
}

.admin-panel-card-button:hover {
  border: 2px solid black;
  color: #000;
}

table {
  margin-top: 10px;
  border: 2px solid black;
  border-radius: 8px;
}

th {
  text-align: left;
  color: white;
  background: #999;
  padding: 2px;
}

.tr-head {
  padding: 2px 2px;
}

td {
  padding: 2px 2px;
  text-align: left;
}

/*
===============================================================
== BEGIN VERTICAL CAROUSSEL
===============================================================
*/
.wrapperCarousel {
  background-size: cover;
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}

.carousel {
  position: relative;
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.carousel__item {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  padding: 0 12px;
  opacity: 0;
  filter: drop-shadow(0 2px 2px #555);
  will-change: transform, opacity;
  -webkit-animation: carousel-animate-vertical 27s linear infinite;
  animation: carousel-animate-vertical 27s linear infinite;
}

.carousel__item:nth-child(1) {
  -webkit-animation-delay: calc(3s * -1);
  animation-delay: calc(3s * -1);
}

.carousel__item:nth-child(2) {
  -webkit-animation-delay: calc(3s * 0);
  animation-delay: calc(3s * 0);
}

.carousel__item:nth-child(3) {
  -webkit-animation-delay: calc(3s * 1);
  animation-delay: calc(3s * 1);
}

.carousel__item:nth-child(4) {
  -webkit-animation-delay: calc(3s * 2);
  animation-delay: calc(3s * 2);
}

.carousel__item:nth-child(5) {
  -webkit-animation-delay: calc(3s * 3);
  animation-delay: calc(3s * 3);
}

.carousel__item:nth-child(6) {
  -webkit-animation-delay: calc(3s * 4);
  animation-delay: calc(3s * 4);
}

.carousel__item:nth-child(7) {
  -webkit-animation-delay: calc(3s * 5);
  animation-delay: calc(3s * 5);
}

.carousel__item:nth-child(8) {
  -webkit-animation-delay: calc(3s * 6);
  animation-delay: calc(3s * 6);
}

.carousel__item:last-child {
  -webkit-animation-delay: calc(-3s * 2);
  animation-delay: calc(-3s * 2);
}

.carousel__item-head {
  border-radius: 50%;
  background-color: #d7f7fc;
  width: 90px;
  height: 90px;
  padding: 14px;
  position: relative;
  margin-right: -45px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
}

.carousel__item-body {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px 20px 16px 70px;
}

.title {
  text-transform: uppercase;
  font-size: 20px;
  margin-top: 10px;
}

@-webkit-keyframes carousel-animate-vertical {
  0% {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
  3%,
  11.1111111111% {
    transform: translateY(100%) scale(0.7);
    opacity: 0.4;
    visibility: visible;
  }
  14.1111111111%,
  22.2222222222% {
    transform: translateY(0) scale(1);
    opacity: 1;
    visibility: visible;
  }
  25.2222222222%,
  33.3333333333% {
    transform: translateY(-100%) scale(0.7);
    opacity: 0.4;
    visibility: visible;
  }
  36.3333333333% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: visible;
  }
  100% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes carousel-animate-vertical {
  0% {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
  3%,
  11.1111111111% {
    transform: translateY(100%) scale(0.7);
    opacity: 0.4;
    visibility: visible;
  }
  14.1111111111%,
  22.2222222222% {
    transform: translateY(0) scale(1);
    opacity: 1;
    visibility: visible;
  }
  25.2222222222%,
  33.3333333333% {
    transform: translateY(-100%) scale(0.7);
    opacity: 0.4;
    visibility: visible;
  }
  36.3333333333% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: visible;
  }
  100% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
}
/*
===============================================================
== END VERTICAL CAROUSSEL
===============================================================
*/

.purchase-nft button {
  background: hsl(141, 71%, 48%);
  color: #fff;
  border: 0;
  padding: 8px;
  margin: 20px 0px 10px 10px;
  border-radius: 8px;
  cursor: pointer;
}

input[type="number"] {
  width: 75px;
}

.admin-panel-card-input-number {
  background: #ccc;
  border: 2px solid black;
  border-radius: 0.8rem;
  color: black;
  font-weight: bold;
  outline: none;
  height: 2rem;
  width: 300px;
  overflow: hidden;
  font-weight: 100;
  margin-right: 10px;
  padding-left: 10px;
}

.btc-wallet,
.nft-minter {
  padding: 1rem;
  border-radius: 8px;
  background-color: #e3ffa8;
  color: #000000;
  margin: 0.5rem;
  -webkit-box-shadow: 0.25em 0.25em 2em rgba(0, 0, 0, 0.25);
  box-shadow: 0.25em 0.25em 2em rgba(0, 0, 0, 0.25);
}

.btc-wallet-input,
.nft-minter-input {
  background: #ccc;
  border: 2px solid black;
  border-radius: 0.8rem;
  color: black;
  font-weight: bold;
  outline: none;
  height: 2rem;
  width: 300px;
  overflow: hidden;
  font-weight: 100;
  margin-right: 10px;
  padding-left: 10px;
}

.btc-wallet-button,
.nft-minter-button {
  background: hsl(141, 71%, 48%);
  color: #fff;
  border: 0;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}

.nft-card-tokenid {
  position: absolute;
  left: 155px;
  top: 90px;
  background: white;
  padding: 0px 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0.1em 0.1em 0.5em rgba(0, 0, 0, 0.25);
  box-shadow: 0.1em 0.1em 0.5em rgba(0, 0, 0, 0.25);
}

.nft-card-nft-type {
  position: absolute;
  left: 75px;
  top: 247px;
  color: white;
  font-weight: 400;
}

.nft-card-kilos-per-liter {
  position: absolute;
  left: 155px;
  top: 247px;
  color: white;
  font-weight: 400;
}

.nft-card-expected-kilos {
  position: absolute;
  left: 260px;
  top: 247px;
  color: white;
  font-weight: 400;
}

.nft-card-rarity {
  position: absolute;
  left: 75px;
  top: 327px;
  color: white;
  font-weight: 400;
}

.nft-card-total-trees {
  position: absolute;
  left: 155px;
  top: 327px;
  color: white;
  font-weight: 400;
}

.nft-card-potential-oil {
  position: absolute;
  left: 260px;
  top: 327px;
  color: white;
  font-weight: 400;
}

.nft-card-price-per-liter {
  position: absolute;
  left: 70px;
  top: 407px;
  color: white;
  font-weight: 400;
}

.nft-card-percentage-reward {
  position: absolute;
  left: 158px;
  top: 407px;
  color: white;
  font-weight: 400;
}

.nft-card-btc-price {
  position: absolute;
  left: 260px;
  top: 407px;
  color: white;
  font-weight: 400;
}

.nft-card-btc-profit {
  position: absolute;
  left: 130px;
  top: 457px;
  background: white;
  padding: 0px 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0.1em 0.1em 0.5em rgba(0, 0, 0, 0.25);
  box-shadow: 0.1em 0.1em 0.5em rgba(0, 0, 0, 0.25);
  font-weight: 400;
}

/*
===============================================================
== BEGIN ROTATING CARD
===============================================================
*/
.card {
  -webkit-perspective: 150rem;
  perspective: 150rem;
  -moz-perspective: 150rem;
  position: relative;
  height: 34rem;
  width: 24rem;
}
.card__side {
  height: 34rem;
  width: 24rem;
  -webkit-transition: all 0.8s ease;
  transition: all 0.8s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 8px;
  overflow: hidden;
  -webkit-box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
}
.card__side--front {
  background-color: #fff;
}
.card__side--back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.card:hover .card__side--front {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.card:hover .card__side--back {
  -webkit-transform: rotateY(0);
  transform: rotateY(0);
}

@media only screen and (min-width: 480px) {
  .nft-wrapper {
    padding: 1rem;
    border-radius: 8px;
    color: #000000;
    margin: 0.5rem;
    -webkit-box-shadow: 0.25em 0.25em 2em rgba(0, 0, 0, 0.25);
    box-shadow: 0.25em 0.25em 2em rgba(0, 0, 0, 0.25);
  }
}

@media only screen and (min-width: 910px) {
  .nft-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-gap: 1em;
    gap: 1em;
  }
}

.content-wrapper {
  padding: 1rem;
  border-radius: 8px;
  color: #000000;
  margin: 0.5rem;
  -webkit-box-shadow: 0.25em 0.25em 2em rgba(0, 0, 0, 0.25);
  box-shadow: 0.25em 0.25em 2em rgba(0, 0, 0, 0.25);
}
/*
===============================================================
== END ROTATING CARD
===============================================================
*/

/*
===============================================================
== START ACCORDION FAQ
===============================================================
*/
.faq-header {
  font-size: 42px;
  padding: 24px;
}

.faq-content {
  margin: 0 auto;
}

.faq-question {
  padding: 0 0;
}

.faq-panel-title {
  font-size: 1.2rem;
  padding: 1rem;
  border-radius: 8px;
  background-color: #e3ffa8;
  color: #000000;
  margin: 0;
  padding: 10px 10px 15px 48px;
  -webkit-box-shadow: 0.25em 0.25em 2em rgba(0, 0, 0, 0.25);
  box-shadow: 0.25em 0.25em 2em rgba(0, 0, 0, 0.25);
  position: relative;
  display: block;
  cursor: pointer;
}

.faq-panel-content {
  padding: 1rem;
  border-radius: 8px;
  background-color: #ffffff;
  color: #000000;
  margin: 0;
  padding: 10px 10px 15px 48px;
  -webkit-box-shadow: 0.25em 0.25em 2em rgba(0, 0, 0, 0.25);
  box-shadow: 0.25em 0.25em 2em rgba(0, 0, 0, 0.25);
  height: 0;
  overflow: hidden;
  z-index: -1;
  position: relative;
  opacity: 0;
  -webkit-transition: 0.4s ease;
  -moz-transition: 0.4s ease;
  -o-transition: 0.4s ease;
  transition: 0.4s ease;
}

.faq-panel:checked ~ .faq-panel-content {
  height: auto;
  opacity: 1;
  padding: 14px;
}

.faq-plus {
  position: absolute;
  margin-left: 20px;
  margin-top: 4px;
  z-index: 5;
  font-size: 42px;
  line-height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: 0.2s ease;
  -moz-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
}

.faq-panel:checked ~ .faq-plus {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.faq-panel {
  display: none;
}
/*
===============================================================
== END ACCORDION FAQ
===============================================================
*/

/*
===============================================================
== BEGIN GALLERY
===============================================================
*/
#gallery {
  display: grid;
  height: calc(100vh - 10px);
  grid-template: repeat(6, 1fr) / repeat(6, 1fr);
  grid-gap: 0.5em;
}
@media (max-width: 800px) {
  #gallery {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
  }
  #gallery > div {
    width: 48%;
    margin: 1%;
  }
}
@media (max-width: 800px) and (max-width: 350px) {
  #gallery > div {
    width: 98%;
  }
}
#gallery > div:nth-child(6n + 1) {
  grid-column: span 2;
  grid-row: span 2;
}
#gallery > div:nth-child(2) {
  grid-column: span 3;
  grid-row: span 3;
}
#gallery > div:nth-child(4) {
  grid-column: span 1;
  grid-row: span 2;
}
#gallery > div > a {
  opacity: 0;
  position: absolute;
  color: #fff;
  background-color: #000;
  font: bold 4em "Helvetica";
  text-shadow: 0 -1px 5px #fff, -1px 0px 5px #fff, 0 1px 5px #fff,
    1px 0px 5px #fff;
  padding: 2rem;
  mix-blend-mode: difference;
  width: 100%;
  height: 100%;
  transition: all ease 1s;
}
#gallery > div > img {
  width: 100%;
  min-height: 100%;
  transition: all ease 1s;
}
#gallery > div:hover img {
  filter: blur(4px);
}
#gallery > div:hover a {
  opacity: 1;
}
#gallery > div {
  overflow: hidden;
  position: relative;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2), 0 3px 20px 0 rgba(0, 0, 0, 0.19);
}
#gallery div,
#gallery a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

[id^="lightbox-"] {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  opacity: 0;
  transition: opacity 450ms ease-in-out;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}
[id^="lightbox-"]:target {
  opacity: 1;
  pointer-events: inherit;
}
[id^="lightbox-"]:target img {
  filter: blur(0);
}
[id^="lightbox-"] .content {
  max-width: 90%;
  position: relative;
  color: #fff;
}
[id^="lightbox-"] .content:hover > a.close {
  opacity: 1;
  transform: scale(1, 1);
}
[id^="lightbox-"] .content:hover > .title {
  opacity: 1;
  transform: translateY(-3px);
}
[id^="lightbox-"] .content:hover > .title::after {
  opacity: 1;
}
[id^="lightbox-"] .content > * {
  transition: all 450ms ease-in-out;
}
[id^="lightbox-"] .title {
  display: block;
  color: #fff;
  margin: 0;
  padding: 1em;
  position: absolute;
  bottom: 0;
  width: 100%;
  transform: translateY(50%);
  font-size: 1.5em;
  opacity: 0;
}
[id^="lightbox-"] .title::after {
  content: " ";
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  transition: all 350ms ease-in-out 250ms;
  opacity: 0;
  transform-origin: bottom;
  mix-blend-mode: soft-light;
}
[id^="lightbox-"] img {
  max-height: 85vh;
  max-width: 100%;
  margin: 0;
  padding: 0;
  filter: blur(50px);
}
[id^="lightbox-"] a.close {
  width: 2em;
  height: 2em;
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0, 0);
  opacity: 0;
  transform-origin: right top;
  text-decoration: none;
  color: #fff;
}
/* [id^="lightbox-"] a.close::after {
  content: "X";
} */
/*
===============================================================
== END GALLERY
===============================================================
*/
/*
===============================================================
== BEGIN TIMELINE
=============================================================== 
*/

.timeline ul {
  background: #456990;
  padding: 50px 0;
}

.timeline ul li {
  list-style-type: none;
  position: relative;
  width: 6px;
  margin: 0 auto;
  padding-top: 50px;
  background: #fff;
}

.timeline ul li::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: inherit;
  z-index: 1;
}

.timeline ul li div {
  position: relative;
  bottom: 0;
  width: 400px;
  padding: 15px;
  background: #f45b69;
}

.timeline ul li div::before {
  content: "";
  position: absolute;
  bottom: 7px;
  width: 0;
  height: 0;
  border-style: solid;
}

.timeline ul li:nth-child(odd) div {
  left: 45px;
}

.timeline ul li:nth-child(odd) div::before {
  left: -15px;
  border-width: 8px 16px 8px 0;
  border-color: transparent #f45b69 transparent transparent;
}

.timeline ul li:nth-child(even) div {
  left: -439px;
}

.timeline ul li:nth-child(even) div::before {
  right: -15px;
  border-width: 8px 0 8px 16px;
  border-color: transparent transparent transparent #f45b69;
}

time {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 8px;
}

/* EFFECTS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.timeline ul li::after {
  transition: background 0.5s ease-in-out;
}

.timeline ul li.in-view::after {
  background: #f45b69;
}

.timeline ul li div {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.timeline ul li:nth-child(odd) div {
  transform: translate3d(200px, 0, 0);
}

.timeline ul li:nth-child(even) div {
  transform: translate3d(-200px, 0, 0);
}

.timeline ul li.in-view div {
  transform: none;
  visibility: visible;
  opacity: 1;
}

/* GENERAL MEDIA QUERIES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

@media screen and (max-width: 900px) {
  .timeline ul li div {
    width: 250px;
  }
  .timeline ul li:nth-child(even) div {
    left: -289px;
    /*250+45-6*/
  }
}

@media screen and (max-width: 600px) {
  .timeline ul li {
    margin-left: 20px;
  }
  .timeline ul li div {
    width: calc(100vw - 91px);
  }
  .timeline ul li:nth-child(even) div {
    left: 45px;
  }
  .timeline ul li:nth-child(even) div::before {
    left: -15px;
    border-width: 8px 16px 8px 0;
    border-color: transparent #f45b69 transparent transparent;
  }
}

/* EXTRA/CLIP PATH STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.timeline-clippy ul li::after {
  width: 40px;
  height: 40px;
  border-radius: 0;
}

.timeline-rhombus ul li::after {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.timeline-rhombus ul li div::before {
  bottom: 12px;
}

.timeline-star ul li::after {
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
}

.timeline-heptagon ul li::after {
  clip-path: polygon(
    50% 0%,
    90% 20%,
    100% 60%,
    75% 100%,
    25% 100%,
    0% 60%,
    10% 20%
  );
}

.timeline-infinite ul li::after {
  animation: scaleAnimation 2s infinite;
}

@keyframes scaleAnimation {
  0% {
    transform: translateX(-50%) scale(1);
  }
  50% {
    transform: translateX(-50%) scale(1.25);
  }
  100% {
    transform: translateX(-50%) scale(1);
  }
}
/*
===============================================================
== END TIMELINE
=============================================================== 
*/

/*
===============================================================
== BEGIN FOOTER
===============================================================
*/
.wrapperFooter {
  display: inline-flex;
  list-style: none;
  align-items: center;
  margin: 40px auto;
}

.wrapperFooter .icon {
  position: relative;
  background: #ffffff;
  border-radius: 50%;
  padding: 15px;
  margin: 10px;
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapperFooter .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #ffffff;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapperFooter .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapperFooter .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wrapperFooter .icon:hover span,
.wrapperFooter .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapperFooter .facebook:hover,
.wrapperFooter .facebook:hover .tooltip,
.wrapperFooter .facebook:hover .tooltip::before {
  background: #1877f2;
  color: #ffffff;
}

.wrapperFooter .twitter:hover,
.wrapperFooter .twitter:hover .tooltip,
.wrapperFooter .twitter:hover .tooltip::before {
  background: #1da1f2;
  color: #ffffff;
}

.wrapperFooter .instagram:hover,
.wrapperFooter .instagram:hover .tooltip,
.wrapperFooter .instagram:hover .tooltip::before {
  background: #e4405f;
  color: #ffffff;
}

.wrapperFooter .github:hover,
.wrapperFooter .github:hover .tooltip,
.wrapperFooter .github:hover .tooltip::before {
  background: #333333;
  color: #ffffff;
}

.wrapperFooter .youtube:hover,
.wrapperFooter .youtube:hover .tooltip,
.wrapperFooter .youtube:hover .tooltip::before {
  background: #cd201f;
  color: #ffffff;
}

footer {
  width: 100%;
  height: 40px;
  padding-top: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
}
/*
===============================================================
== END FOOTER
===============================================================
*/

/*
==============================================================
== NAVIGATION
==============================================================
*/
.toggle-button {
  width: 3rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding-top: 0;
  padding-bottom: 0;
  vertical-align: middle;
}

.toggle-button:focus {
  outline: none;
}

.toggle-button:hover > .toggle-button__bar,
.toggle-button:active > .toggle-button__bar {
  height: 0.1rem;
  background: hsl(141, 71%, 48%);
}

.toggle-button:active {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.toggle-button__bar {
  width: 80%;
  height: 0.2rem;
  background: black;
  display: block;
  margin: 0.4rem 0;
}

.main-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  padding: 0.5rem 1rem;
  z-index: 1;
}

.main-header > div {
  display: inline-block;
  vertical-align: middle;
}

.toggle-button-div {
  display: inline-block;
  vertical-align: middle;
}

.main-header__brand {
  color: #000;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.5rem;
  height: 3rem;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: -0.5rem;
}

.main-header__brand:hover {
  color: hsl(141, 71%, 48%);
  cursor: pointer;
}

.main-nav {
  display: none;
}

.main-nav__items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-nav__item {
  display: inline-block;
  margin: 0 1rem;
}

.main-nav__item a,
.mobile-nav__item a {
  text-decoration: none;
  color: #000;
  padding: 0.2rem 0;
}

.main-nav__item a:hover,
.main-nav__item a:active {
  color: hsl(141, 71%, 48%);
  border-bottom: 2px solid black;
}

@media (min-width: 40rem) {
  .toggle-button {
    display: none;
  }

  .main-nav {
    display: inline-block;
    text-align: right;
    /* width: calc(100% - 200px); */
    vertical-align: -webkit-baseline-middle;
  }
}

.mobile-nav {
  display: none;
  position: fixed;
  z-index: 101;
  left: 0;
  background: hsl(141, 71%, 48%);
  opacity: 95%;
  color: #fff;
  width: 100%;
  height: 100vh;
}

.mobile-nav__items {
  width: 90%;
  height: 100%;
  list-style: none;
  margin: 10% auto;
  padding: 0;
  text-align: center;
}

.mobile-nav__item {
  margin: 1rem 0;
}

.mobile-nav__item a {
  font-size: 1.5rem;
}

.mobile-nav__item a:hover {
  margin: 1rem 0;
  background: #000;
  color: #fff;
  padding: 8px;
  border-radius: 8px;
}

.open {
  display: block !important;
}
